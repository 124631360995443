import * as React from "react";
import Seo from "../components/layout/seo";
import styled from "styled-components";
import WaveBackground from "../components/backgrounds/WaveBackground";
import { H1, MediumText } from "../components/styles/TextStyles";
import { themes } from "../components/styles/ColorStyles";

const NotFoundPage = () => {
  return (
    <LayoutWrapper>
      <Seo title="Eroare 404" />
      <Wrapper>
        <WaveBackground />
        <ContentWrapper>
          <TextWrapper>
            <Title>404</Title>
            <Description>Te-ai pierdut? Pagina nu exista.</Description>
          </TextWrapper>
        </ContentWrapper>
      </Wrapper>
    </LayoutWrapper>
  );
};

export default NotFoundPage;

const LayoutWrapper = styled.div``;

const Wrapper = styled.div`
  overflow: hidden;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  max-width: 1234px;
  margin: 0 auto;
  padding: 200px 30px;
  display: grid;
  justify-content: space-between;

  @media (max-width: 450) {
    grid-template-columns: auto;
    gap: 60px;
    padding: 150px 20px 250px;
  }
`;

const TextWrapper = styled.div`
  max-width: 360px;
  display: grid;
  gap: 30px;
`;

const Title = styled(H1)`
  color: ${themes.dark.text1};
  background: linear-gradient(180deg, #730040 0%, #301cbe 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;

  @media (max-width: 450px) {
    font-size: 58px;
  }
`;

const Description = styled(MediumText)`
  font-size: 17px;
  line-height: 130%;
`;
